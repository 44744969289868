import Toast, { useToast } from "vue-toastification"
import type { PluginOptions } from "vue-toastification"

const options: PluginOptions = {
    containerClassName: "z-[9999] fixed top-4 right-4 flex flex-col ml-4",
    icon: false,
    closeButton: false,
    transition: "Vue-Toastification__slideBlurred",
}

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(Toast, options)
    return {
        provide: {
            toast: useToast()
        }
    }
})
