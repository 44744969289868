import validate from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/ESGComplianceFE/impact-up/middleware/auth.global.ts";
import client_45auth from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/client-auth.mjs";
import manifest_45route_45rule from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  client_45auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/ESGComplianceFE/impact-up/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/auth.mjs"),
  "guest-only": () => import("/vercel/path0/ESGComplianceFE/impact-up/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/guest-only.mjs")
}