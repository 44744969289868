import FetchFactory from '../factory';
import type { ICovers } from '~/repository/types/covers'
import type { IOrgs } from '~/repository/types/organisations'

class OrganisationModule extends FetchFactory {
  private RESOURCE = '/org';

  async getQuestionnaires(orgId: string): Promise<any> {
    return await this.call<ICovers>(
      'POST',
      `${this.RESOURCE}/getquestionnairecoversbyorgid`,
      {'OrganisationId': orgId}
    )
  }

  async search(text: string) {
    return await this.call<IOrgs>(
      'POST',
      `${this.RESOURCE}/searchorganisation`,
      {
        "SearchTerm": text,
        "PageNumber" : "1",
        "PageSize" : "50"
      }
    )
  }
}

export default OrganisationModule;
