<template>
    <footer class="mt-auto py-3 bg-primary">
        <div class="px-8 py-24 max-w-xl mx-auto">
            <nav class="columns-2 sm:flex sm:items-center sm:justify-center ">
                <div v-for="item in footer" class="text-center mb-3 sm:ml-12 sm:first:ml-0 sm:mb-0">
                     <NuxtLink :key="item.name" :to="item.href" class="text-white font-semibold">{{ item.name }}</NuxtLink>
                </div>
            </nav>
            <div class="flex items-center justify-center mt-10">
                <a href="http://www.facebook.com"><span class="i-mdi-facebook bg-white h-6 w-6"></span></a>
                <a class="ml-10" href="http://www.instagram.com"><span class="i-mdi-instagram bg-white h-6 w-6"></span></a>
                <a class="ml-10" href="http://www.twitter.com"><span class="i-mdi-twitter bg-white h-6 w-6"></span></a>
                <a class="ml-10" href="http://www.linkedin.com"><span class="i-mdi-linkedin bg-white h-6 w-6"></span></a>
                <a class="ml-10" href="http://www.youtube.com"><span class="i-mdi-youtube bg-white h-6 w-6"></span></a>
            </div>
            <p class="mt-10 text-center text-white">© 2023 {{ appConfig.name }}, Inc. All rights reserved.</p>
        </div>
    </footer>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const footer = [
    { name: 'About', href: '/about-us' },
    { name: 'FAQs', href: '/faqs' },
    { name: 'Contact Us', href: '/contact-us' },
]
</script>