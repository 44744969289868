import HttpFactory from '../factory'
import type { IAnswers } from '~/repository/types/answers';

class QuestionnaireModule extends HttpFactory {
  private RESOURCE = '/answers';

  async get(orgId: string, questionId: string): Promise<any> {
    return await this.call<IAnswers>(
      'POST',
      `${this.RESOURCE}/getanswer`,
      {
        'organisationId': orgId,
        'questionnaireId': questionId
      }
    );
  }

  async update(data: IAnswers): Promise<any> {
    return await this.call<IAnswers>(
      'POST',
      `${this.RESOURCE}/saveanswers`,
      data
    );
  }
}

export default QuestionnaireModule;
