import FetchFactory from '../factory';
import type { ICovers } from '~/repository/types/covers'
import type { IQuestionnaire } from '~/repository/types/questionnaire';
import type { IBadges } from '~/repository/types/badges';
import type { IBadge } from '~/repository/types/badge';
import type { IAllAnswers } from '~/repository/types/answers';
import type { IAccounts } from '~/repository/types/accounts';
import type { IAccount } from '~/repository/types/account';

class AdminModule extends FetchFactory {
  private RESOURCE = '/admin';

  async getQuestionnaires(page: number, size: number): Promise<any> {
    return await this.paginatedCall<ICovers>(
      'POST',
      `${this.RESOURCE}/admincovers`,
      {},
      page,
      size
    )
  }

  async createQuestionnaire(data: IQuestionnaire): Promise<any> {
    return await this.call<IQuestionnaire>(
      'POST',
      `${this.RESOURCE}/createquestionnaire`,
      data
    );
  }

  async getBadges(page: number, size: number): Promise<any> {
    return await this.paginatedCall<IBadges>(
      'POST',
      `${this.RESOURCE}/badges`,
      {},
      page,
      size
    );
  }

  async createBadge(data: IBadge): Promise<any> {
    return await this.call<IBadge>(
      'POST',
      `${this.RESOURCE}/createbadge`,
      data
    );
  }
  
  async saveBadge(data: IBadge): Promise<any> {
    return await this.call<IBadge>(
      'POST',
      `${this.RESOURCE}/savebadgebyid`,
      data
    );
  }

  async getQuestions(): Promise<any> {
    return await this.call<IBadge>(
      'POST',
      `${this.RESOURCE}/badgequestions`,
      {}
    );
  }

  async getAnswers(page: number, size: number): Promise<any> {
    return await this.paginatedCall<IAllAnswers>(
      'POST',
      `${this.RESOURCE}/getanswers`,
      {},
      page,
      size
    );
  }

  async getUsers(page: number, size: number, data: object): Promise<any> {
    return await this.paginatedCall<IAccounts>(
      'POST',
      `${this.RESOURCE}/getusers`,
      data,
      page,
      size
    );
  }

  async updateUser(data: IAccount): Promise<any> {
    return await this.call<IAccount>(
      'POST',
      `${this.RESOURCE}/saveuserbyid`,
      data
    );
  }
}

export default AdminModule;
