import "preline/preline"
import { type IStaticMethods } from "preline/preline"
import { debounce } from 'perfect-debounce'
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  onNuxtReady(() => {
    const targetNode = document.getElementById("page-container")
    if (targetNode) {
      const config = { childList: true, subtree: true }
      const callback = debounce(async () => {
        window.HSStaticMethods.autoInit()
      }, 100)
      const observer = new MutationObserver(callback)
      observer.observe(targetNode, config)
    }
  })

  nuxtApp.hook("page:finish", () => {
    window.HSStaticMethods.autoInit()
  })
})
