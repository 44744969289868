
import { $fetch,FetchOptions } from 'ofetch';
import QuestionnaireModule from '~/repository/modules/questionnaire';
import OrganisationModule from '~/repository/modules/organisation';
import AdminModule from '~/repository/modules/admin';
import AnswersModule from '~/repository/modules/answers'
import UserModule from '~/repository/modules/user'

/** ApiInstance interface provides us with good typing */
interface IApiInstance {
  questionnaire: QuestionnaireModule,
  organisation: OrganisationModule,
  admin: AdminModule,
  answers: AnswersModule,
  user: UserModule,
}

export default defineNuxtPlugin((nuxtApp) => {
  const fetchOptions: FetchOptions = {
    baseURL: useRuntimeConfig().public.API_BASE_URL,
  }

  /** create a new instance of $fetcher with custom option */
  const apiFetcher = $fetch.create(fetchOptions);

  /** an object containing all repositories we need to expose */
  const modules: IApiInstance = {
    questionnaire: new QuestionnaireModule(apiFetcher),
    organisation: new OrganisationModule(apiFetcher),
    admin: new AdminModule(apiFetcher),
    answers: new AnswersModule(apiFetcher),
    user: new UserModule(apiFetcher),
  };

  return {
    provide: {
      api: modules,
    },
  };
});