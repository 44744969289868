import { defineStore } from 'pinia'
import type { ILogin} from '~/repository/types/login'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as ILogin | null,
    token: null,
    error: null,
  }),
  getters: {
    hasUser(): boolean {
      return this.user !== null
    },
    getId(): string {
      return this.user?.Id
    },
    getOrgId(state): string {
      return state.user?.OrganisationId
    },
    getToken(): string {
      return this.token
    },
    getPaymentEnum(): Record<number, string> | undefined {
      return this.user?.PaymentAccessTierEnum
    },
    getQuestionnaireEnum(): Record<number, string> {
      let resp: Record<number, string> = {}
      if (this.user === null) {
        return resp
      }

      for (const index in this.user.QuestionnaireStatusEnum) {
        resp[index] = camelToSpace(this.user.QuestionnaireStatusEnum[index])
      }

      return resp
    },
    getQuestionEnum(): Record<number, string> {
      let resp: Record<number, string> = {}
      if (this.user === null) {
        return resp
      }
      for (let i = 0; i < 3; i++) {
        resp[i] = camelToSpace(this.user.QuestionTypeEnum[i])
      }
      return resp
    },
  },
  actions: {
    async login(force: boolean = false) {
      if (force || this.user === null) {
        this.user = null
        try {
          if (this.token === null) {
            this.token = await useNuxtApp().$api.user.token()
          }
          
          const { session } = useAuth()
          this.user = await useNuxtApp().$api.user.login(session.value.user.id)
          console.log(this.user)
        } catch (error) {
          this.error = error
        }
      }
    },

    async logout() {
      this.user = null
      this.token = null
    },
  },
  persist: true,
})