import unhead_KgADcZ0jPj from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/ESGComplianceFE/impact-up/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jBAFIEN7H9 from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/@hebilicious/authjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_i8AMfKeYnY from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/vercel/path0/ESGComplianceFE/impact-up/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import api_GFfDXud5Cr from "/vercel/path0/ESGComplianceFE/impact-up/plugins/api.ts";
import preline_client_NKwNbVglRD from "/vercel/path0/ESGComplianceFE/impact-up/plugins/preline.client.ts";
import toast_client_9z8IIRO0Dx from "/vercel/path0/ESGComplianceFE/impact-up/plugins/toast.client.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_jBAFIEN7H9,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  api_GFfDXud5Cr,
  preline_client_NKwNbVglRD,
  toast_client_9z8IIRO0Dx
]