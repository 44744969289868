import { default as _91_46_46_46slug_93ry0Sa2rJ4kMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/[...slug].vue?macro=true";
import { default as _91id_93OYujGAhjZuMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/accounts/[id].vue?macro=true";
import { default as indexoDByyKH2DjMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/accounts/index.vue?macro=true";
import { default as _91id_93r5gpWW3VQvMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/badge/[id].vue?macro=true";
import { default as index14lDZ4Ca2NMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/badge/index.vue?macro=true";
import { default as dashboardtuoZjQnJIwMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/dashboard.vue?macro=true";
import { default as profileLajlgL5RARMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/profile.vue?macro=true";
import { default as _91id_93TwKyl4apQ0Meta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/questionnaire/[id].vue?macro=true";
import { default as _91id_93f0fpscqEFLMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/questionnaire/edit/[id].vue?macro=true";
import { default as indexGWi26914CzMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/questionnaire/edit/index.vue?macro=true";
import { default as editMKdrh55uOxMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/review/edit.vue?macro=true";
import { default as indexE2tWefFOG2Meta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/review/index.vue?macro=true";
import { default as settingsZh3IaZAMZhMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/admin/settings.vue?macro=true";
import { default as _91slug_93mqSIBP7IvLMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/blog/[slug].vue?macro=true";
import { default as indexpTiF1dxjBmMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/blog/index.vue?macro=true";
import { default as contact_45usxKEVbUFmSbMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/contact-us.vue?macro=true";
import { default as faqsWx611cinBSMeta } from "/vercel/path0/ESGComplianceFE/impact-up/pages/faqs.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ry0Sa2rJ4kMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ry0Sa2rJ4kMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ry0Sa2rJ4kMeta || {},
    alias: _91_46_46_46slug_93ry0Sa2rJ4kMeta?.alias || [],
    redirect: _91_46_46_46slug_93ry0Sa2rJ4kMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OYujGAhjZuMeta?.name ?? "admin-accounts-id",
    path: _91id_93OYujGAhjZuMeta?.path ?? "/admin/accounts/:id()",
    meta: _91id_93OYujGAhjZuMeta || {},
    alias: _91id_93OYujGAhjZuMeta?.alias || [],
    redirect: _91id_93OYujGAhjZuMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/accounts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoDByyKH2DjMeta?.name ?? "admin-accounts",
    path: indexoDByyKH2DjMeta?.path ?? "/admin/accounts",
    meta: indexoDByyKH2DjMeta || {},
    alias: indexoDByyKH2DjMeta?.alias || [],
    redirect: indexoDByyKH2DjMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93r5gpWW3VQvMeta?.name ?? "admin-badge-id",
    path: _91id_93r5gpWW3VQvMeta?.path ?? "/admin/badge/:id()",
    meta: _91id_93r5gpWW3VQvMeta || {},
    alias: _91id_93r5gpWW3VQvMeta?.alias || [],
    redirect: _91id_93r5gpWW3VQvMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/badge/[id].vue").then(m => m.default || m)
  },
  {
    name: index14lDZ4Ca2NMeta?.name ?? "admin-badge",
    path: index14lDZ4Ca2NMeta?.path ?? "/admin/badge",
    meta: index14lDZ4Ca2NMeta || {},
    alias: index14lDZ4Ca2NMeta?.alias || [],
    redirect: index14lDZ4Ca2NMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/badge/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardtuoZjQnJIwMeta?.name ?? "admin-dashboard",
    path: dashboardtuoZjQnJIwMeta?.path ?? "/admin/dashboard",
    meta: dashboardtuoZjQnJIwMeta || {},
    alias: dashboardtuoZjQnJIwMeta?.alias || [],
    redirect: dashboardtuoZjQnJIwMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: profileLajlgL5RARMeta?.name ?? "admin-profile",
    path: profileLajlgL5RARMeta?.path ?? "/admin/profile",
    meta: profileLajlgL5RARMeta || {},
    alias: profileLajlgL5RARMeta?.alias || [],
    redirect: profileLajlgL5RARMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/profile.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TwKyl4apQ0Meta?.name ?? "admin-questionnaire-id",
    path: _91id_93TwKyl4apQ0Meta?.path ?? "/admin/questionnaire/:id()",
    meta: _91id_93TwKyl4apQ0Meta || {},
    alias: _91id_93TwKyl4apQ0Meta?.alias || [],
    redirect: _91id_93TwKyl4apQ0Meta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/questionnaire/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93f0fpscqEFLMeta?.name ?? "admin-questionnaire-edit-id",
    path: _91id_93f0fpscqEFLMeta?.path ?? "/admin/questionnaire/edit/:id()",
    meta: _91id_93f0fpscqEFLMeta || {},
    alias: _91id_93f0fpscqEFLMeta?.alias || [],
    redirect: _91id_93f0fpscqEFLMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/questionnaire/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGWi26914CzMeta?.name ?? "admin-questionnaire-edit",
    path: indexGWi26914CzMeta?.path ?? "/admin/questionnaire/edit",
    meta: indexGWi26914CzMeta || {},
    alias: indexGWi26914CzMeta?.alias || [],
    redirect: indexGWi26914CzMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/questionnaire/edit/index.vue").then(m => m.default || m)
  },
  {
    name: editMKdrh55uOxMeta?.name ?? "admin-review-edit",
    path: editMKdrh55uOxMeta?.path ?? "/admin/review/edit",
    meta: editMKdrh55uOxMeta || {},
    alias: editMKdrh55uOxMeta?.alias || [],
    redirect: editMKdrh55uOxMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/review/edit.vue").then(m => m.default || m)
  },
  {
    name: indexE2tWefFOG2Meta?.name ?? "admin-review",
    path: indexE2tWefFOG2Meta?.path ?? "/admin/review",
    meta: indexE2tWefFOG2Meta || {},
    alias: indexE2tWefFOG2Meta?.alias || [],
    redirect: indexE2tWefFOG2Meta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/review/index.vue").then(m => m.default || m)
  },
  {
    name: settingsZh3IaZAMZhMeta?.name ?? "admin-settings",
    path: settingsZh3IaZAMZhMeta?.path ?? "/admin/settings",
    meta: settingsZh3IaZAMZhMeta || {},
    alias: settingsZh3IaZAMZhMeta?.alias || [],
    redirect: settingsZh3IaZAMZhMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/admin/settings.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mqSIBP7IvLMeta?.name ?? "blog-slug",
    path: _91slug_93mqSIBP7IvLMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93mqSIBP7IvLMeta || {},
    alias: _91slug_93mqSIBP7IvLMeta?.alias || [],
    redirect: _91slug_93mqSIBP7IvLMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexpTiF1dxjBmMeta?.name ?? "blog",
    path: indexpTiF1dxjBmMeta?.path ?? "/blog",
    meta: indexpTiF1dxjBmMeta || {},
    alias: indexpTiF1dxjBmMeta?.alias || [],
    redirect: indexpTiF1dxjBmMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contact_45usxKEVbUFmSbMeta?.name ?? "contact-us",
    path: contact_45usxKEVbUFmSbMeta?.path ?? "/contact-us",
    meta: contact_45usxKEVbUFmSbMeta || {},
    alias: contact_45usxKEVbUFmSbMeta?.alias || [],
    redirect: contact_45usxKEVbUFmSbMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: faqsWx611cinBSMeta?.name ?? "faqs",
    path: faqsWx611cinBSMeta?.path ?? "/faqs",
    meta: faqsWx611cinBSMeta || {},
    alias: faqsWx611cinBSMeta?.alias || [],
    redirect: faqsWx611cinBSMeta?.redirect,
    component: () => import("/vercel/path0/ESGComplianceFE/impact-up/pages/faqs.vue").then(m => m.default || m)
  }
]