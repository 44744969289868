import type { IProfile } from '~/repository/types/profile';
import HttpFactory from '../factory';
import type { ILogin } from '~/repository/types/login';

class UserModule extends HttpFactory {
  private RESOURCE = '/user';

  async login(id: string): Promise<any> {
    return await this.call<ILogin>(
      'POST',
      `${this.RESOURCE}/login`,
      { Id: id }
    );
  }
  
  async token(): Promise<any> {

    const headers = useRequestHeaders(['cookie']) as HeadersInit

    const { data } = await useFetch('/api/token', { headers })

    return data.value
  }

  async get(id: string): Promise<any> {
    return await this.call<IProfile>(
      'POST',
      `${this.RESOURCE}/getprofilebyid`,
      { Id: id }
    );
  }

  async update(data: IProfile): Promise<any> {
    return await this.call<IProfile>(
      'POST',
      `${this.RESOURCE}/updateprofile`,
      data
    );
  }
}

export default UserModule;
