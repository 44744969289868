export function roundUp(num: number) {
    return Math.ceil(num)
}

export function camelToSpace(val: string) {
    return val.replace(/([A-Z])/g, ' $1').trim()
}

export function spaceToSnake(val: string) {
    return val.trim().replace(/[\s\.]+/g, '_')
}

export function toDateString(val: string) {
    return typeof val === 'string' ? new Date(val).toLocaleString('en-GB', { timeZone: 'UTC', year: "numeric", month: "long", day: "numeric", hour12: false, hour: "numeric", minute: "2-digit", second: "2-digit"}) : ''
}

export function getPublished(post: object) {
    const pub = new Date(post.firstPublished)
    return pub.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })
}

export function getCastPlugin(): Function {
    return (node) => {
        node.hook.input((value, next) => next(value === undefined ? undefined : Number(value)))
    }
}
