import { $Fetch, FetchOptions } from 'ofetch';
import { useUserStore } from '~/stores/user';

/*
 The FetchFactory acts as a wrapper around an HTTP client. 
 It encapsulates the functionality for making API requests asynchronously 
 through the call function, utilizing the provided HTTP client.
*/
class FetchFactory {
  private $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @returns 
   */
  async call<T>(
    method: string,
    url: string,
    data?: object,
    fetchOptions?: FetchOptions<'json'>
  ): Promise<T> {
    const user = useUserStore()

    return this.$fetch<T>(
      url + '?code=' + useAppConfig().azureCode,
      { 
        method, 
        headers: {Authentication: 'Bearer ' + user.getToken},
        body: data, 
        ...fetchOptions 
      }
    )
  }
  
  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param page requested page number
   * @param perPage requested page size
   * @param fetchOptions fetch options
   * @returns 
   */
  async paginatedCall<T>(
    method: string,
    url: string,
    data?: object,
    page: number = 1,
    perPage: number = 15,
    fetchOptions?: FetchOptions<'json'>
  ): Promise<T> {
    const pageObj = {pageNumber: page, pageSize: perPage}
    
    return this.call(
      method,
      url,
      { ...data, ...pageObj},
      fetchOptions
    )
  }
}

export default FetchFactory;