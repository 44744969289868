import type { IQuestionnaire } from '~/repository/types/questionnaire';
import HttpFactory from '../factory'

class QuestionnaireModule extends HttpFactory {
  private RESOURCE = '/questionnaire';

  async get(id: string): Promise<any> {
    return await this.call<IQuestionnaire>(
      'POST',
      `${this.RESOURCE}/getquestionnairebyid`,
      {'id': id}
    );
  }

  async save(data: IQuestionnaire): Promise<any> {
    return await this.call<IQuestionnaire>(
      'POST',
      `${this.RESOURCE}/savequestionnairebyid`,
      data
    );
  }
}

export default QuestionnaireModule;
